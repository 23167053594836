<template>
    <div class="layout slide-panel">
        <div class="flex xs6">
            <div
                v-if="PRODUCT_ADVERTISING_PLUS == value.plays?.[0]?.product_id"
                class="triangle-image">
                <img src="/img/avts-plus-icon.png">
            </div>
            <img
                :src="heroImage"
                class="slide-image">

            <div class="action-buttons">
                <div
                    v-if="value.unsupported"
                    class="status-unsupported">
                    {{ value.error }}
                </div>
                <div
                    v-else
                    class="status-deployed">
                    <styled-button
                        class="play-button"
                        :loading="loading"
                        @click="$emit('select', value)">
                        DEPLOY
                    </styled-button>
                </div>
                <div
                    v-if="value.marketing_assets.has_preview"
                    class="preview">
                    <a
                        href="#"
                        @click.prevent="$emit('preview', value)">
                        Preview Creative
                    </a>
                </div>
            </div>
        </div>

        <div class="flex xs6 play-content">
            <h2 class="play-title">
                {{ value.display_name }}
            </h2>

            <p>
                <truncate-text
                    :value="value.marketing_assets.description"
                    :characters="75" />
            </p>

            <div
                v-if="value.marketing_assets.types && value.marketing_assets.types.length">
                <h5>
                    Campaign types ({{ value.marketing_assets.types.length }})
                </h5>
                <div class="campaign-types">
                    <div
                        v-for="type in value.marketing_assets.types"
                        :key="type">
                        {{ type }}
                    </div>
                </div>
            </div>
            <div
                class="play-channels">
                <div v-if="value.marketing_assets.channels.includes('Facebook')">
                    <icon
                        name="meta"
                        size="28" />
                </div>
                <div v-if="value.marketing_assets.channels.includes('Snapchat')">
                    <icon
                        name="snapchat"
                        size="25" />
                </div>
                <div v-if="value.marketing_assets.channels.includes('TikTok')">
                    <icon
                        name="tiktok"
                        size="24" />
                </div>
                <div v-if="value.marketing_assets.channels.includes('Pinterest')">
                    <icon
                        name="pinterest"
                        size="24" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import TruncateText from '@/components/globals/TruncateText';
import StyledButton from '@/components/globals/StyledButton';
import { PRODUCT_ADVERTISING_PLUS } from '@/helpers/globals';
import Icon from '@/components/globals/Icon';

export default {
    components: {
        StyledButton,
        TruncateText,
        Icon
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            PRODUCT_ADVERTISING_PLUS
        };
    },
    computed: {
        heroImage() {
            const image = get(this.value, 'marketing_assets.hero_image_url', null);
            return image || '/img/plays/hero/generic-pb-bkgd.jpg';
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-image {
    max-width: 100%;
    height: auto;
}

.slide-panel {
    box-shadow: 0 0 10px rgba($black, 0.1);
    background: $white;
    min-height: 260px;
    padding: 10px;
}

.triangle-image {
    img {
        width:36px;
        height:30px;
        margin-top:11px;
    }
    clip-path:polygon(0 0, 0% 100%, 100% 0);
    background:white;
    width:80px;
    height:80px;
    position: absolute;
    z-index: 1;
}

.play-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    color: $gray-dark;
    margin-bottom: 1rem;
}
.play-channels {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $gray-light;
    div{
        margin-right: 5px;
    }
}
.play-content {
    color: $gray-dark;
    margin-left: 1.5rem;
    h5 {
        color: $gray-dark;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
}
.campaign-types {
    display: flex;
    flex-wrap: wrap;
    & > div {
        margin-right: 1.5rem;
        &:last-child {
            margin-right: 0;
        }
    }
}

.preview {
    margin-top: 0.5rem;
}

.play-button {
    font-size: 18px;
    font-weight: 600;
    min-width: 160px;
}

.action-buttons {
    margin: 1rem 0;
    text-align: center;
}
</style>
