<template>
    <div
        class="channel"
        @mouseover="hoverActive = true"
        @mouseleave="hoverActive = false">
        <div
            v-if="channelFeaturesLength && hoverActive"
            class="playbook-popup">
            <div class="playbook-blue">
                This channel is still missing dependencies:
            </div>
            <ul>
                <li
                    v-for="feature in channel.features"
                    :key="feature.id">
                    {{ feature.display_name }}
                </li>
            </ul>
        </div>
        <img
            class="logo"
            :src="'/img/platforms/' + fbToMeta(channel.name, true, true) + '.svg'">
        <div class="name">
            {{ fbToMeta(channel.display_name) }}
        </div>
        <a
            v-if="channelFeaturesLength || notEnrolled"
            href="#"
            @click.prevent="choosingSetup()">Setup</a>
        <styled-checkbox
            v-else
            :key="checkboxKey"
            :value="channel.name"
            :checked="false"
            :row="false"
            @change="setSelections($event)" />
    </div>
</template>

<script>
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import { mapGetters, mapState } from 'vuex';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        StyledCheckbox,
    },
    props: {
        channel: {
            type: Object,
            required: true
        },
        playbook: {
            type: Object,
            required: true
        },
        notEnrolled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            hoverActive: false,
            checkboxKey: 0,
            fbToMeta
        };
    },
    watch: {
        playbook() {
            this.checkboxKey += 1; // re-init checkbox component each time to remove previous selection from it
        }
    },
    computed: {
         ...mapGetters([
            'dealerProducts',
            'activeDealerOnboardings'
        ]),
        ...mapState({
            dealer: state => state.dealer.currentDealer,
        }),
        channelFeaturesLength() {
            return this.channel?.features?.length ?? 0;
        }
    },
    methods: {
        choosingSetup() {
            const haveOnboarding = this.activeDealerOnboardings.filter(o => o.products?.data?.[0]?.name.includes(this.channel.display_name));
            const haveProduct = this.dealerProducts.filter(o => o.name.includes(this.channel.display_name));

            // Condition 1 - determining if we have active onboarding for that channel
            if (haveOnboarding.length) {
                this.$router.push({
                    name: 'dealer-onboarding',
                    params: {
                        dealer_id: this.dealer.id,
                        onboarding_id: haveOnboarding[0].id
                    }
                });
            }

            // Condition 2 - if no current onboarding but product available for that channel
            else if (haveProduct.length) {
                this.$store.dispatch('startNewOnboarding', {
                    dealerId: this.dealer.id,
                    productIds: [ haveProduct[0].id ]
                });
            }

            // Condition 3 - if no product or current onboarding let's go to product selection
            else {
                this.$router.push({
                    name: 'channel-setup-product-selection',
                    params: {
                        dealer_id: this.dealer.id,
                        channel: this.channel.name
                    }
                });
            }
        },
        setSelections(e) {
            this.$emit('emit-selection', e);
        }
    },
};
</script>


<style lang="scss" scoped>
.channel{
    padding: 0 25px;
    position: relative;
}
.logo{
    width: 100%;
    max-width: 30px;
    max-height: 30px;
}
.name{
    color: black;
    margin-bottom: 15px;
}
.playbook-popup{
    width: 350px;
    text-align: left;
    position: absolute;
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
    padding: 25px;
    bottom: calc(100% + 25px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    box-sizing: border-box;
    z-index: 999;
    &:after{
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        transform: translateX(-50%) rotate(45deg) skew(-10deg, -10deg);
        bottom: -13px;
        left: 50%;
        background: white;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
    }
    &:before{
        content: '';
        position: absolute;
        background: white;
        width: 100px;
        height: 20px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
    }
}
.playbook-blue{
    color: $blue-primary;
    font-weight: bold;
    margin-bottom: 5px;
}
</style>