<template>
    <div
        v-if="value.marketing_assets"
        :class="{
            'layout play': true,
            'play-unsupported': value.unsupported
        }">
        <div
            v-if="PRODUCT_ADVERTISING_PLUS == value.plays?.[0]?.product_id"
            class="triangle-image">
            <img src="/img/avts-plus-icon.png">
        </div>
        <div
            class="flex">
            <img
                class="play-image"
                :src="heroImage">
        </div>
        <div class="flex xs10 layout pa-4">
            <div class="flex xs6 pl-2 pr-4">
                <h2 class="play-title">
                    {{ value.display_name }}
                </h2>
                <p v-if="value.marketing_assets.description">
                    <truncate-text
                        :value="value.marketing_assets.description"
                        :characters="115" />
                </p>
                <div
                    class="play-channels">
                    <div v-if="value.marketing_assets.channels.includes('Facebook')">
                        <icon
                            name="meta"
                            size="28" />
                    </div>
                    <div v-if="value.marketing_assets.channels.includes('Snapchat')">
                        <icon
                            name="snapchat"
                            size="25" />
                    </div>
                    <div v-if="value.marketing_assets.channels.includes('TikTok')">
                        <icon
                            name="tiktok"
                            size="24" />
                    </div>
                    <div v-if="value.marketing_assets.channels.includes('Pinterest')">
                        <icon
                            name="pinterest"
                            size="24" />
                    </div>
                </div>
            </div>

            <div
                v-if="value.marketing_assets.types && value.marketing_assets.types.length">
                <h5>
                    Campaign types ({{ value.marketing_assets.types.length }})
                </h5>
                <div class="campaign-types">
                    <div
                        v-for="type in value.marketing_assets.types"
                        :key="type">
                        {{ type }}
                    </div>
                </div>
            </div>

            <div class="flex xs2 ml-auto layout align-center justify-center pl-4 play-button-container border-left column">
                <div
                    v-if="value.unsupported"
                    class="status-unsupported">
                    {{ value.error }}
                </div>
                <div
                    v-else
                    class="status-deployed">
                    <styled-button
                        class="play-button"
                        :loading="loading"
                        @click="$emit('select', value)">
                        DEPLOY
                    </styled-button>
                </div>
                <div
                    v-if="value.marketing_assets.has_preview"
                    class="preview">
                    <a
                        href="#"
                        @click.prevent="$emit('preview', value)">
                        Preview Creative
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PRODUCT_ADVERTISING_PLUS } from '@/helpers/globals';
import TruncateText from '../globals/TruncateText';
import StyledButton from '../globals/StyledButton';
import { get } from 'lodash';
import Icon from '@/components/globals/Icon';

export default {
    components: {
        TruncateText,
        StyledButton,
        Icon
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            PRODUCT_ADVERTISING_PLUS
        };
    },
    computed: {
        heroImage() {
            const image = get(this.value, 'marketing_assets.hero_image_url', null);
            return image || '/img/plays/hero/generic-pb-bkgd.jpg';
        }
    },
};
</script>

<style lang="scss" scoped>

.play {
    box-shadow: 0 0 10px rgba($black, 0.1);
    background: $white;
    padding: 10px;
    &.play-unsupported {
        color: $gray;
        a {
            color: $gray;
        }
        .play-image {
            filter: grayscale(1);
        }
        .play-logo {
            filter: grayscale(1);
        }
        .play-title {
            color: $gray !important;
        }
        .status-unsupported {
            color: $carnation;
            text-transform: uppercase;
            max-width: 200px;
            text-align: center;
        }
    }
    .play-image {
        max-width: 100%;
        height: auto;
        max-height: 190px;
        display: block;
    }
    .play-logo {
        max-width: 60px;
        max-height: 60px;
        height: auto;
    }
    .play-title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        color: $gray-dark;
        margin-bottom: 1rem;
    }
    .play-channels {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        margin-top: 15px;
        div{
            margin-right: 5px;
        }
    }
    .play-button-container {
        min-width: 150px;
    }
    .play-button {
        font-size: 18px;
        font-weight: bold;
        white-space: nowrap;
    }
    .continue-onboarding-button {
        padding: 1rem;
        font-size: 12px;
        line-height: 14px;
        white-space: normal;
    }
    h5 {
        color: $gray-dark;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    .campaign-types {
        display: flex;
        & > div {
            margin-right: 1.5rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .border-left {
        border-left: 1px solid $alabaster-dark;
    }
    .border-right {
        border-right: 1px solid $alabaster-dark;
    }

    .triangle-image {
        img {
            width:36px;
            height:30px;
            margin-top:11px;
        }
        clip-path:polygon(0 0, 0% 100%, 100% 0);
        background:white;
        width:80px;
        height:80px;
        margin-top: -1px;
        position: absolute;
        z-index: 1;
    }

    .preview {
        margin-top: 1rem;
    }
}
</style>
