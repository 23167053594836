<template>
    <div class="no-results-row">
        <div class="no-results-content">
            <h2 class="font-weight-bold">
                There are no playbooks matching your search.
            </h2>
            <p>
                If you'd like to talk with our team about creating a playbook
                specific to your needs click the button below to get in touch
                with us!
            </p>
            <styled-button
                class="no-results-button"
                @click="onRequestNewPlaybook">
                LET'S CHAT
            </styled-button>
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';

export default {
    components: {
        StyledButton
    },
    methods: {
        onRequestNewPlaybook() {
            this.$store.dispatch('createTicket', {
                subject: 'New Playbook Request',
                type: 'Other'
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.no-results-row {
    display: flex;
    align-items: center;

    box-shadow: 0 0 10px rgba($black, 0.1);
    background: $white;
    min-height: 190px;
    background-image: url('/img/plays/hero/get-in-touch-full.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 0 55%;
    background-position: bottom left;
    h2 {
        font-size: 1.8rem;
    }
    @media (min-width: $bp-lg) {
        padding: 0 0 0 360px;

        background-position: 0 0;

    }
}

.no-results-content {
    text-align: center;
    padding: 1.5rem 1rem;
}

.no-results-button {
    font-size: 18px;
    font-weight: bold;
}
</style>