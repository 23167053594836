<template>
    <div
        :class="{
            'play': true,
            'play-unsupported': value.unsupported
        }">
        <div
            v-if="PRODUCT_ADVERTISING_PLUS == value.plays?.[0]?.product_id"
            class="triangle-image">
            <img src="/img/avts-plus-icon.png">
        </div>
        <img
            class="play-image"
            :src="heroImage">
        <div
            class="play-channels">
            <div v-if="value.marketing_assets.channels.includes('Facebook')">
                <icon
                    name="meta"
                    size="28" />
            </div>
            <div v-if="value.marketing_assets.channels.includes('Snapchat')">
                <icon
                    name="snapchat"
                    size="25" />
            </div>
            <div v-if="value.marketing_assets.channels.includes('TikTok')">
                <icon
                    name="tiktok"
                    size="24" />
            </div>
            <div v-if="value.marketing_assets.channels.includes('Pinterest')">
                <icon
                    name="pinterest"
                    size="24" />
            </div>
        </div>

        <div class="play-content">
            <h2 class="play-title">
                {{ value.display_name }}
            </h2>

            <p v-if="value.marketing_assets.description">
                <truncate-text
                    :value="value.marketing_assets.description"
                    :characters="130" />
            </p>
            <div
                v-if="value.marketing_assets.types && value.marketing_assets.types.length">
                <h5>
                    Campaign types ({{ value.marketing_assets.types.length }})
                </h5>
                <div class="campaign-types">
                    <div
                        v-for="type in value.marketing_assets.types"
                        :key="type">
                        {{ type }}
                    </div>
                </div>
            </div>
        </div>
        <div class="play-actions">
            <div
                v-if="value.unsupported"
                class="status-unsupported">
                {{ value.error }}
            </div>
            <div
                v-else
                class="status-deployed">
                <styled-button
                    class="play-button"
                    :loading="loading"
                    @click="$emit('select', value)">
                    DEPLOY
                </styled-button>
            </div>
            <div
                v-if="value.marketing_assets.has_preview"
                class="preview">
                <a
                    href="#"
                    @click.prevent="$emit('preview', value)">
                    Preview Creative
                </a>
            </div>
        </div>
        <div
            v-if="legacyPlays.length"
            class="legacy-plays">
            <div v-if="value.marketing_assets.legacy_label">
                {{ value.marketing_assets.legacy_label }}
            </div>
            <div v-else>
                This was used in
            </div>
            <template v-for="(legacyPlay, index) in legacyPlays">
                <span
                    v-if="index > 0"
                    :key="legacyPlay.name">
                    &amp;
                </span>
                <img
                    :key="`play-${legacyPlay.name}`"
                    :src="legacyPlay.logo">
            </template>

            <styled-tooltip v-if="value.marketing_assets.legacy_info">
                <template #content>
                    {{ value.marketing_assets.legacy_info }}
                </template>
                <action-button
                    icon="question-circle"
                    size="18" />
            </styled-tooltip>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import TruncateText from '@/components/globals/TruncateText';
import StyledButton from '@/components/globals/StyledButton';
import { PRODUCT_ADVERTISING_PLUS } from '@/helpers/globals';
import Icon from '@/components/globals/Icon';

export default {
    components: {
        ActionButton,
        StyledTooltip,
        StyledButton,
        TruncateText,
        Icon
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            PRODUCT_ADVERTISING_PLUS
        };
    },
    computed: {
        heroImage() {
            const image = get(this.value, 'marketing_assets.hero_image_url', null);
            return image || '/img/plays/hero/generic-pb-bkgd.jpg';
        },
        legacyPlays() {
            if (!this.value.marketing_assets.legacy_plays?.length) {
                return [];
            }

            const map = {
                'Pro': '/img/pro-logo-horizontal.png',
                'Ultimate': '/img/ultimate-logo-horizontal.png'
            };

            return this.value.marketing_assets.legacy_plays.map(play => ({
                name: play,
                logo: map[play]
            }));
        }
    },
};
</script>

<style lang="scss" scoped>

.play {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: $white;
    color: $gray;
    @media (min-width: $bp-md) {
        width: calc(50% - 30px);
    }
    @media (min-width: $bp-lg) {
        width: calc(33.333% - 30px);
    }
    &.play-unsupported {
        color: $gray;
        a {
            color: $gray;
        }
        .play-image {
            filter: grayscale(1);
        }
        .play-logo {
            filter: grayscale(1);
        }
        .play-title {
            color: $gray !important;
        }
        .status-unsupported {
            color: $carnation;
            text-transform: uppercase;
            text-align: center;
        }
    }
    .separator {
        border-top: 1px solid $alabaster-dark;
    }
    .play-image {
        max-width: 100%;
        height: auto;
        margin: 20px;
    }
    .play-logo {
        max-width: 60px;
        max-height: 60px;
        height: auto;
    }
    .play-title {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        color: $gray-dark;
        margin-bottom: 2rem;
    }
    .play-channels {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        margin: 0 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray-light;
        div{
            margin-right: 5px;
        }
    }
    .play-content {
        color: $gray-dark;
        padding: 1rem 2rem 3rem;
        h5 {
            color: $gray-dark;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
    }
    .campaign-types {
        display: flex;
        flex-wrap: wrap;
        & > div {
            margin-right: 1.5rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .continue-onboarding-button {
        padding: 1rem;
        font-size: 12px;
        line-height: 14px;
    }

    .triangle-image {
        img {
            width: 36px;
            height: 30px;
            margin-top: 8px;
        }
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        background: white;
        width: 80px;
        height: 80px;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
    .play-actions {
        margin: auto 2rem 2rem;
        text-align: center;
        .play-button {
            font-size: 18px;
            font-weight: 600;
            min-width: 160px;
        }
    }
    .preview {
        margin-top: 1rem;
    }
    .legacy-plays {
        border-top: 1px solid $alabaster-dark;
        display: flex;
        align-items: center;
        padding: 1rem;
        font-size: 0.8rem;
        & > * {
            margin-right: 0.8rem;
            &:last-child {
                margin-right: 0;
                margin-left: auto;
            }
        }
        img {
            height: 20px;
            width: auto;
        }
    }
}
</style>
