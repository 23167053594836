<template>
    <styled-slideout
        :value="value"
        :width="1100"
        @input="$emit('input', $event)">
        <div
            v-if="playbook"
            class="play-preview">
            <div class="preview-content">
                <h2>Playbook: {{ playbook.display_name }}</h2>
                <p v-if="playbook.marketing_assets.description">
                    {{ playbook.marketing_assets.description }}
                </p>
                <div
                    v-if="playbook.marketing_assets.types && playbook.marketing_assets.types.length">
                    <h5>
                        Campaign types ({{ playbook.marketing_assets.types.length }})
                    </h5>
                    <div class="campaign-types">
                        <div
                            v-for="type in playbook.marketing_assets.types"
                            :key="type">
                            {{ type }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="loading"
                class="ma-5">
                <loader label="Loading previews..." />
            </div>
            <div
                v-if="previewCampaigns"
                class="previews">
                <div
                    v-for="campaign in previewCampaigns"
                    :key="campaign.id"
                    class="campaign">
                    <div
                        v-for="adset in campaign.adsets"
                        :key="adset.id"
                        class="adset">
                        <div
                            v-for="ad in adset.ads"
                            :key="ad.id"
                            class="ad">
                            <facebook-ad-preview
                                v-if="ad.preview"
                                :html="ad.preview" />
                            <!-- <div
                                v-if="ad.preview"
                                class="ad-preview"
                                v-html="ad.preview" /> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import Loader from '@/components/globals/Loader';
import FacebookAdPreview from '@/components/globals/FacebookAdPreview';

export default {
    components: {
        StyledSlideout,
        Loader,
        FacebookAdPreview
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        playbook: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            error: null,
            loading: false,
            previewData: []
        };
    },
    computed: {
        previewCampaigns() {
            // Restructure to ensure data exists
            return this.previewData.map(campaign => {
                if (!campaign.adsets) {
                    campaign.adsets = [];
                    return campaign;
                }

                campaign.adsets = campaign.adsets.map(adset => {
                    if (!adset.ads) {
                        adset.ads = [];
                        return adset;
                    }

                    adset.ads = adset.ads.map(ad => {

                        // Distill down the ad preview
                        ad.preview = ad.previews?.data?.[0]?.body || null;
                        return ad;
                    });

                    return adset;
                });

                return campaign;
            });
        }
    },
    watch: {
        playbook: {
            immediate: true,
            handler(playbook, lastPlaybook) {
                if (playbook == null || playbook?.id == lastPlaybook?.id) {
                    return;
                }
                this.loadPreviews();
            }
        }
    },
    created() {

    },
    methods: {
        async loadPreviews() {
            try {
                this.error = null;
                this.loading = true;
                this.previewData = [];

                const play = this.playbook.plays?.[0];

                const response = await this.$http.get(`plays/${play.id}/campaign-previews`);

                this.previewData = response.data.data;

            } catch(error) {
                console.error('Could not retrieve previews for this play', error.response || error);
                this.error = error.response?.data?.error?.messages?.[0] || 'There was an error retrieving previews';
            } finally {
                this.loading = false;
            }
        },

    }
};
</script>

<style lang="scss" scoped>
.preview-content {
    margin: 2rem;
    h2 {
        margin-bottom: 0.5rem
    }
    h5 {
        color: $gray-dark;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}

.adset {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ad {
     padding: 1rem;
}

.campaign-types {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    & > div {
        margin-right: 1.5rem;
        &:last-child {
            margin-right: 0;
        }
    }
}
</style>