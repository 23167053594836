<template>
    <div 
        class="facebook-ad-preview"
        :style="{
            width: size.width + 'px',
            height: size.height + 'px'
        }">
        <transition name="fade">
            <div 
                v-if="loading"
                class="loading-overlay">
                <loader 
                    :label="false"
                    class="loader" />
            </div>
        </transition>
        <iframe 
            v-if="src"
            :src="src"
            :width="size.width"
            :height="size.height"
            :class="{
                'frame-loaded': !loading
            }"
            @load="loading = false" />
    </div>
</template>

<script>
import Loader from './Loader';

export default {
    components: {
        Loader
    },
    props: {
        html: {
            type: String,
            default: ''
        },
        placement: {
            type: String,
            default: 'DESKTOP_FEED_STANDARD'
        }
    },
    data() {
        return {
            loading: false,
            src: null
        };
    },
    computed: {
        size() {
            // Map sizes as arrays for legibility here
            const map = {
                'DESKTOP_FEED_STANDARD': [505,830]
            };
            // Restructure into object to legibility elsewhere
            const [ width, height ] = map[this.placement] || [505,830];
            return { width, height };
        }
    },
    watch: {
        html: {
            immediate: true,
            handler(value, previousValue) {
                if (value == previousValue) {
                    return;
                }

                // Create the iframe so we can be sure we're getting
                // the right src attribute
                const iframe = this.createElementFromHTML(value);

                if (iframe) {
                    this.loading = true;
                    this.src = iframe.src;
                }
            }
        }
    },
    created() {

    },
    methods: {
        createElementFromHTML(htmlString) {
            const div = document.createElement('div');
            div.innerHTML = htmlString.trim();

            // Change this to div.childNodes to support multiple top-level nodes
            return div.firstChild; 
        }
    }
};
</script>

<style lang="scss" scoped>
.facebook-ad-preview {
    position: relative;
    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($white, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    iframe {
        border: none;
        overflow: hidden;
    }
}
</style>

