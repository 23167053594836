<template>
    <div class="toggle-view">
        <button
            :class="{
                'active': value == 'grid'
            }"
            @click="$emit('input', 'grid')">
            <icon name="grid" />
        </button>
        <button
            :class="{
                'active': value == 'list'
            }"
            @click="$emit('input', 'list')">
            <icon name="list" />
        </button>
    </div>
</template>

<script>
import Icon from './Icon';

export default {
    components: {
        Icon
    },
    props: {
        value: {
            type: String,
            default: 'list'
        }
    }
};
</script>

<style lang="scss" scoped>
.toggle-view {
    button {
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;
        &:first-child {
            margin-right: 6px;
        }
        &.active {
            opacity: 1;
        }
    }
}
</style>

