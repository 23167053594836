<template>
    <styled-dialog
        :value="value"
        :width="600"
        @input="$emit('input', $event)">
        <h2 class="mb-2 mt-4 text-xs-center">
            Channel Selection
        </h2>
        <p class="text-xs-center mb-5">
            Choose what channel(s) you want to deploy this playbook to below:
        </p>
        <template>
            <v-layout align-center>
                <loader
                    v-if="loader"
                    class="playbook-loader" />
                <div
                    v-else
                    class="playbook-channels">
                    <div
                        v-for="channel in allowedEnrolledChannels"
                        :key="channel.id">
                        <playbooks-modal-channel
                            :channel="channel"
                            :playbook="playbook"
                            :not-enrolled="false"
                            @emit-selection="getSelections" />
                    </div>
                    <div
                        v-for="channel in allowedNotEnrolledChannels"
                        :key="channel.name">
                        <playbooks-modal-channel
                            :channel="channel"
                            :playbook="playbook"
                            :not-enrolled="true"
                            @emit-selection="getSelections" />
                    </div>
                </div>
            </v-layout>
            <div
                v-if="showMessage"
                class="playbooks-message">
                Please note that while Facebook-only deployments are automated, multi-channel deployments require additional turnaround time.
                Most orders can be fulfilled within 3 business days, but delays may occur due to factors such as
                <button
                    class="underline font-weight-bold"
                    @click="showMore = !showMore">
                    those listed here.
                </button>
                <ul
                    v-if="showMore"
                    class="pt-1">
                    <li>Platform delays outside of our control (pending whitelisting requests, etc)</li>
                    <li>Ad Account technical or billing issues</li>
                    <li>Requests requiring customization to copy or creative</li>
                    <li>Requests requiring custom targeting (Oracle provisioning, zip code lists, etc)</li>
                    <li>Requests for additional languages - Periods of abnormally high multi-channel deployment requests</li>
                </ul>
            </div>
            <div class="layout justify-center">
                <styled-button
                    v-if="value.onboarding && selectedChannels.has('facebook')"
                    :disabled="disabledButton"
                    class="mt-4 font-weight-bold"
                    @click="$emit('select', playbook, true)">
                    CONTINUE
                </styled-button>
                <div
                    v-else-if="value.unsupported && selectedChannels.has('facebook')"
                    :disabled="disabledButton"
                    class="mt-4 playbook-unsupported">
                    {{ value.error }}
                </div>
                <styled-button
                    v-else-if="value.deployed && selectedChannels.has('facebook')"
                    :disabled="disabledButton"
                    class="mt-4 font-weight-bold"
                    :loading="loading"
                    @click="$emit('select', playbook, true)">
                    RE-DEPLOY
                </styled-button>
                <styled-button
                    v-else-if="selectedChannels.has('facebook') && selectedChannels.size == 1"
                    :disabled="disabledButton"
                    class="mt-4 font-weight-bold"
                    :loading="loading"
                    @click="$emit('select', playbook, true)">
                    DEPLOY
                </styled-button>
                <styled-button
                    v-else
                    :disabled="disabledButton"
                    class="mt-4 font-weight-bold"
                    @click="openSlideout">
                    SUBMIT ORDER
                </styled-button>
            </div>
        </template>
        <template #actions>
            <a
                href="#"
                class="red--text"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
        </template>
    </styled-dialog>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import Loader from '@/components/globals/Loader';
import PlaybooksModalChannel from '@/components/playbooks/PlaybooksModalChannel';
import { mapState, mapGetters } from 'vuex';
import {
    FACEBOOK_FEATURES,
    SNAPCHAT_FEATURES,
    TIKTOK_FEATURES,
    PINTEREST_FEATURES
} from '@/helpers/globals';
import { getNotEnrolledChannels } from '@/helpers/getNotEnrolledChannels';
import { getAvailableChannels } from '@/helpers/getAvailableChannels';

export default {
    components: {
        StyledDialog,
        StyledButton,
        PlaybooksModalChannel,
        Loader
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        playbook: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loader: false,
            availableChannels: [],
            notEnrolledChannels: [],
            showMessage: false,
            disabledButton: true,
            facebookIncompleteFeatures: [],
            tiktokIncompleteFeatures: [],
            snapchatIncompleteFeatures: [],
            pinterestIncompleteFeatures: [],
            showMore: false,
            selectedChannels: new Set(),
            channels: []
        };
    },
    computed: {
        ...mapGetters([
            'dealerPlatforms',
            'dealerFeatures',
            'dealerFeaturesIncomplete',
            'dealerProducts'
        ]),
        ...mapState({
            currentDealerId: state => state.dealer.currentDealerId,
            dealer: state => state.dealer.currentDealer,
            token: (state) => state.user.token,
            agency: state => state.agency.currentAgency
        }),
        dealerChannels() {
            const channels = this.dealerPlatforms.map(platform => {
                return {
                    ...platform,
                    products: this.dealerProducts.filter( product => {
                        const visibility = product.product_settings?.visibility;
                        return product.platform_id == platform.id && visibility !== 'coming_soon';
                    })
                };
            });
            let filteredChannels = this.removeSomeChannels(channels);
            this.setFeatures(filteredChannels);
            return filteredChannels;
        },
        enrolledChannels() {
            return this.dealerChannels.filter(channel => channel.products.some( product => {
                const visibility = product.product_settings?.visibility;
                return product.public == true && visibility !== 'coming_soon';
            }));
        },
        allowedEnrolledChannels() {
            if (this.channels.length) {
                return this.enrolledChannels.filter(c => this.channels.includes(c.display_name));
            }
            return this.enrolledChannels;
        },
        allowedNotEnrolledChannels() {
            if (this.channels.length) {
                return this.notEnrolledChannels.filter(c => this.channels.includes(c.display_name));
            }
            return this.notEnrolledChannels;
        }
    },
    watch: {
        async dealer() {
            if (this.$route.name == 'dealer-playbooks' || this.$route.name == 'ads-library') {
                this.selectedChannels = new Set();
                this.checkValidFeatures();
                this.loader = true;
                await this.loadDealerPlatforms();
                this.notEnrolledChannels = await getNotEnrolledChannels(this.availableChannels, this.enrolledChannels, this.agency.id, false);
                this.loader = false;
            }
        },
        playbook() {
            this.allowOnlyAvailableChannels();
            this.selectedChannels = new Set();
            this.getSelections();
        }
    },
    methods: {
        allowOnlyAvailableChannels() {
            if (this.playbook.marketing_assets.hasOwnProperty('channels')) {
                this.channels = this.playbook.marketing_assets.channels;
            } else {
                this.channels = [];
            }
        },
        async checkValidFeatures() {
            const validFeatures = [];
            validFeatures.push(...FACEBOOK_FEATURES, ...SNAPCHAT_FEATURES, ...TIKTOK_FEATURES, ...PINTEREST_FEATURES);
            await this.$store.dispatch('updateDealerFeatureStatus', {
                features: validFeatures
            });
            //simple checking if feature has right prefix
            this.facebookIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('facebook') > -1;
            });
            this.snapchatIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('snapchat') > -1;
            });
            this.tiktokIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('tiktok') > -1;
            });
            this.pinterestIncompleteFeatures = this.dealerFeaturesIncomplete.filter(f => {
                return f.feature.indexOf('pinterest') > -1;
            });
        },
        openSlideout() {
            this.$emit('show-slideout', this.selectedChannels);
            this.$emit('input', false);
        },
        async loadDealerPlatforms() {
            if (this.token) {
                const platforms = await this.$apiRepository.getPlatforms();
                const availableChannels = getAvailableChannels(platforms);
                this.availableChannels = this.removeSomeChannels(availableChannels);
                this.setFeatures(this.availableChannels);
            }
        },
        setFeatures(array) {
            array.forEach(channel => {
                if (channel.name === 'facebook') {
                    channel.features = this.facebookIncompleteFeatures;
                }
                if (channel.name === 'snapchat') {
                    channel.features = this.snapchatIncompleteFeatures;
                }
                if (channel.name === 'tiktok') {
                    channel.features = this.tiktokIncompleteFeatures;
                }
                if (channel.name === 'pinterest') {
                    channel.features = this.pinterestIncompleteFeatures;
                }
            });
            return array;
        },
        removeSomeChannels(array) {
            return array.filter(channel => {
                return channel.name !== 'microsoft' && channel.name !== 'google';
            });
        },
        getSelections(e) {
            if (e) {
                if (e.target.checked) {
                    this.selectedChannels.add(e.target.value);
                } else {
                    this.selectedChannels.delete(e.target.value);
                }
            }
            // if only facebook selected then show the message
            if((this.selectedChannels.size == 1 && this.selectedChannels.has('facebook')) || this.selectedChannels.size == 0) {
                this.showMessage = false;
            } else {
                this.showMessage = true;
            }
            // if no selections at all then disable the button
            if (this.selectedChannels.size == 0) {
                this.disabledButton = true;
            } else {
                this.disabledButton = false;
            }
        }
    },
};
</script>

<style lang="scss">
.playbooks-message{
    background-color: #F8CBCB;
    box-sizing: border-box;
    padding: 20px 25px;
    border: 1px solid red;
    margin-top: 30px;
}
.playbook-loader{
    margin: 0 auto;
}
.playbook-channels{
    display:flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}
.playbook-unsupported {
    color: $carnation;
    text-transform: uppercase;
    text-align: center;
}
.v-dialog {
    overflow: visible;
}
.underline {
  text-decoration-line: underline;
}
</style>
