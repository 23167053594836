<template>
    <styled-slideout
        v-model="isOpen"
        label="Playbook Buildout Order"
        @input="$emit('input', $event)">
        <div class="ma-5 playbook-slideout">
            <div class="inf">
                <div class="inf__image">
                    <img
                        :src="imageURL"
                        alt="">
                </div>
                <div class="inf__text">
                    <div class="inf__title">
                        {{ playbookName }}
                    </div>
                    <div class="inf__desc">
                        {{ truncate(description, 140) }}
                    </div>
                </div>
            </div>
            <p class="mb-5">
                You have selected to deploy the {{ playbookName }} playbook across
                <span v-if="facebookSelected">Facebook</span>
                <span v-if="facebookSelected && (snapchatSelected || tiktokSelected || pinterestSelected)"> & </span>
                <span v-if="snapchatSelected">Snapchat</span>
                <span v-if="snapchatSelected && tiktokSelected"> & </span>
                <span v-if="tiktokSelected">Tiktok</span>
                <span v-if="(tiktokSelected || snapchatSelected) && pinterestSelected"> & </span>
                <span v-if="pinterestSelected">Pinterest</span>.
                Omni-Channel deployments currently require some manual steps by the BuyerBridge
                team so please fill out the form below and allow up to 48 hours for your buildout to be completed.
            </p>
            <!-- facebook form -->
            <div
                v-if="showFacebook"
                class="facebook-form">
                <div class="heading">
                    <div class="heading__image">
                        <img
                            class="heading__logo"
                            :src="'/img/platforms/facebook.svg'">
                    </div>
                    <div class="heading__title">
                        Facebook Buildout
                    </div>
                </div>
                <div class="form-holder">
                    <hub-spot-form
                        ref="facebookForm"
                        form-id="3e79bde7-35db-40e7-96d3-332b5eaaca84"
                        :field-values="fieldValues('Facebook')"
                        :hide-button="true"
                        :portal-id="currentPortal"
                        @complete="completeFacebook" />
                </div>
            </div>
            <!-- tiktok form -->
            <div
                v-if="showTiktok"
                class="tiktok-form">
                <div class="heading">
                    <div class="heading__image">
                        <img
                            class="heading__logo"
                            :src="'/img/platforms/tiktok.svg'">
                    </div>
                    <div class="heading__title">
                        Tiktok Buildout
                    </div>
                </div>
                <div class="form-holder">
                    <hub-spot-form
                        ref="tiktokForm"
                        form-id="df465d5f-3107-499e-861b-e3df38d6dde0"
                        :field-values="fieldValues('Tiktok')"
                        :hide-button="true"
                        :portal-id="currentPortal"
                        @complete="completeTiktok" />
                </div>
            </div>
            <!-- snapchat form -->
            <div
                v-if="showSnapchat"
                class="snapchat-form">
                <div class="heading">
                    <div class="heading__image">
                        <img
                            class="heading__logo"
                            :src="'/img/platforms/snapchat.svg'">
                    </div>
                    <div class="heading__title">
                        Snapchat Buildout
                    </div>
                </div>
                <div class="form-holder">
                    <hub-spot-form
                        ref="snapchatForm"
                        form-id="e52706c6-ba0f-47b9-a4ac-5d61fc7db20b"
                        :field-values="fieldValues('Snapchat')"
                        :hide-button="true"
                        :portal-id="currentPortal"
                        @complete="completeSnapchat" />
                </div>
            </div>
            <!-- pinterest form -->
            <div
                v-if="showPinterest"
                class="pinterest-form">
                <div class="heading">
                    <div class="heading__image">
                        <img
                            class="heading__logo"
                            :src="'/img/platforms/pinterest.svg'">
                    </div>
                    <div class="heading__title">
                        Pinterest Buildout
                    </div>
                </div>
                <div class="form-holder">
                    <hub-spot-form
                        ref="pinterestForm"
                        form-id="e40b1219-ca1b-49a5-adb4-6d577eb8627b"
                        :field-values="fieldValues('Pinterest')"
                        :hide-button="true"
                        :portal-id="currentPortal"
                        @complete="completePinterest" />
                </div>
            </div>
            <div class="text-xs-center mt-5">
                <styled-button
                    v-if="!allCompleted"
                    @click="submitForms">
                    SUBMIT ORDER
                </styled-button>
                <div
                    v-else
                    class="final-message">
                    The form has been successfully submitted!
                </div>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import HubSpotForm from '@/components/globals/HubSpotForm.vue';
import { mapState } from 'vuex';

export default {
    components: {
        StyledButton,
        StyledSlideout,
        HubSpotForm,
    },
    props: {
        playbook: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            required: true
        },
        facebookSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
        tiktokSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
        snapchatSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
        pinterestSelected: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            isOpen: false,
            playbookName: '',
            description: '',
            imageURL: '',
            showFacebook: false,
            showSnapchat: false,
            showTiktok: false,
            showPinterest: false,
            allCompleted: false,
            currentPortal: '3753365',
        };
    },
    watch: {
        value() {
            this.isOpen = this.value;
            this.initFields();
        },
        playbook() {
            this.initFields();
        },
        showFacebook() {
            this.checkIfAllDone();
        },
        showTiktok() {
            this.checkIfAllDone();
        },
        showSnapchat() {
            this.checkIfAllDone();
        },
        showPinterest() {
            this.checkIfAllDone();
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            dealer: state => state.dealer.currentDealer,
            agency: state => state.agency.currentAgency
        })
    },
    methods: {
        fieldValues(channel) {
            return {
                ...{
                    'TICKET.agency_id': this.agency.id,
                    'TICKET.agency_name': this.agency.name,
                    'TICKET.ticket___buyerbridge_id': this.dealer.id,
                    'TICKET.form___dealer_name': this.dealer.name,
                    'TICKET.subject': `Deploy - ${this.playbookName} - ${this.dealer.name} - ${channel} - ${this.agency.name}`,
                    'TICKET.ticket___primary_contact__name___email___phone_': this.user.name,
                    'email': this.user.email,
                    'TICKET.playbook_id': this.playbook.id,
                    'TICKET.playbook_name': this.playbookName,
                    'TICKET.ticket_contact_email': this.user.email
                }
            };
        },
        initFields() {
            this.playbookName = this.playbook.display_name;
            this.imageURL = this.playbook.marketing_assets.hero_image_url;
            this.description = this.playbook.marketing_assets?.description;
            this.showFacebook = this.facebookSelected;
            this.showSnapchat = this.snapchatSelected;
            this.showTiktok = this.tiktokSelected;
            this.showPinterest = this.pinterestSelected;
        },
        completeFacebook() {
            this.showFacebook = false;
        },
        completeSnapchat() {
            this.showSnapchat = false;
        },
        completeTiktok() {
            this.showTiktok = false;
        },
        completePinterest() {
            this.showPinterest = false;
        },
        truncate(source, size) {
            if(source) {
                return source.length > size ? source.slice(0, size - 1) + '…' : source;
            } else {
                return source;
            }
        },
        checkIfAllDone() {
            if (!this.showTiktok && !this.showFacebook && !this.showSnapchat && !this.showPinterest) {
                this.allCompleted = true;
            } else {
                this.allCompleted = false;
            }
        },
        submitForms() {
            if(this.$refs.facebookForm) {
                this.$refs.facebookForm.$el.querySelector('form').submit();
            }
            if(this.$refs.tiktokForm) {
                this.$refs.tiktokForm.$el.querySelector('form').submit();
            }
            if(this.$refs.snapchatForm) {
                this.$refs.snapchatForm.$el.querySelector('form').submit();
            }
            if(this.$refs.pinterestForm) {
                this.$refs.pinterestForm.$el.querySelector('form').submit();
            }
        }
    }
};
</script>

<style lang="scss">
.playbook-slideout{
    .inf{
        padding: 15px;
        box-shadow: 1px 1px 10px 5px rgba(0,0,0,0.1);
        display: flex;
        margin-bottom: 30px;
        &__title{
            font-size: 18px;
            font-weight: 700;
        }
        &__desc{
            font-size: 12px;
            line-height: 20px;
        }
        &__image{
            margin-right: 15px;
            img{
                max-width: 146px;
                height: auto;
            }
        }
    }
    .heading{
        display: flex;
        align-items: center;
        margin-bottom: 15px;;
        &__image{
            margin-right: 15px;
            img{
                max-width: 30px;
                height: auto;
            }
        }
        &__title{
            font-size: 20px;
        }
    }
    .final-message{
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 18px;
        color: $success-500;
    }
    .form-holder{
        padding: 0 35px;
    }
}
</style>
